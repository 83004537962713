import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  Nav,
  Navbar,
  NavDropdown,
  FormControl,
  InputGroup,
} from "react-bootstrap"

const ProductHeader = ({ setProductHeader }) => {
  const data = useStaticQuery(graphql`
    query {
      allProductsJson(
        sort: { fields: OrderId, order: ASC }
        filter: { Title: { ne: null } }
      ) {
        edges {
          node {
            OrderId
            CTALink
            Title
            RelatedProduct {
              CTALink
              Title
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setProductHeader(data.allProductsJson.edges)
  }, [data])
  const [showResults, setShowResults] = React.useState(false)

  return (
    <>
      {data.allProductsJson.edges.map(({ node, index }) => (
        <>
          <NavDropdown.Item
            href={"/products/" + node.CTALink}
            /* className="dropdown-toggle" */
            id="itemelemt"
          >
            {node?.Title}
            {/* <div className="children">
              <ul>
                {node?.RelatedProduct?.map((node2, index2) => (
                  <li>
                    <Link
                      to={
                        "/products/" +
                        node.CTALink +
                        "#" +
                        node2?.Title.replace(" ", "")
                      }
                      className="w-100"
                    >
                      {node2?.Title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}
          </NavDropdown.Item>
        </>
      ))}
      <NavDropdown.Item href="/faq" className="" id="itemelemt">
        FAQ
      </NavDropdown.Item>
    </>
  )
}
export default ProductHeader

// export page query
