import React, { useEffect, useState } from "react"
import {
  Nav,
  Navbar,
  NavDropdown,
  FormControl,
  InputGroup,
} from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import ProductHeader from "./products_header"
import { navigate, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

export default function Headerbar() {
  const data = useStaticQuery(graphql`
    query {
      allMenuJson(
        sort: { fields: OrderMenu, order: ASC }
        filter: { CTAText: { ne: null } }
      ) {
        edges {
          node {
            CTALink
            OrderMenu
            CTAText
            submenu {
              CTALink
              CTAText
            }
          }
        }
      }
    }
  `)

  const [showResults, setShowResults] = React.useState(false)
  const [showProduct, setShowProduct] = React.useState(true)
  const [showSectionTwo, setSectionTwo] = React.useState(false)
  const [showDetailProduct, setShowDetailProduct] = React.useState(false)
  const [ProductforMobile, setProductforMobile] = useState([])
  const [productMenu, setproductMenu] = useState([])

  const [showMenu, setShowMenu] = React.useState(false)
  const [ProductName, setProductName] = useState("")
  const [Level3, setLevel3] = useState([])
  const [Level3Name, setLevel3Name] = useState("")
  const [Level3Link, setLevel3Link] = useState("")
  const [showSearch, setShowSearch] = useState(false)

  function setProductHeader(params) {
    setproductMenu(params)
    setProductforMobile(params)
  }

  function OpenCloseNav(Open, name) {
    var child = document.querySelector(`[aria-labelledby="${name}"]`)
    if (Open && !child?.classList.contains("show")) {
      if (child) child?.classList.add("show")
      else document.getElementById(name)?.click()
    } else if (!Open && child?.classList.contains("show")) {
      child?.classList.remove("show")
    }
  }

  function ClickNav(name, link) {
    var child = document.querySelector(`[aria-labelledby="${name}"]`)
    if (child) {
      navigate(link)
    }
  }

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EE69WWRBM2"
        />
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <div className="container-fluid bg-dark top-header">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="co-md-6 ml-auto d-flex align-items-center">
                <Link to="/company">
                  <span className="text-white mr-3 d-none d-sm-block">
                    Company
                  </span>
                </Link>
                <Link to="/mediacenter">
                  <span className="text-white mr-3 d-none d-sm-block">
                    Media Center
                  </span>
                </Link>
                <Link to="/careers">
                  {" "}
                  <span className="text-white mr-3 d-none d-sm-block">
                    Careers
                  </span>
                </Link>
                <a href="tel:+1 (716) 463-6400">
                  {" "}
                  <span className="text-white mr-3">+1 (716) 463-6400</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid border-bottom">
        <div className="container">
          <Navbar expand="lg">
            <span className=" form-control-feedback d-block d-sm-none">
              <FontAwesomeIcon
                icon={faSearch}
                onClick={() => {
                  setShowSearch(x => !x)
                }}
              />
            </span>
            <Navbar.Brand href="/" className="pt-xs-0">
              <img
                alt="Logo Retech"
                src="/img/logo.svg"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <button
              type="button"
              aria-label=""
              className="border-0 menu-collapse"
              onClick={() => setShowMenu(x => !x)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="mr-auto"
                style={{
                  height: "59px",
                  paddingTop: "10px",
                }}
              >
                {data.allMenuJson.edges.map(({ node, index }) => {
                  return (
                    <>
                      {node?.submenu?.length > 0 ? (
                        <NavDropdown
                          title={node.CTAText}
                          id={"service"}
                          className="border-right"
                          onMouseOver={() => {
                            OpenCloseNav(true, "service")
                          }}
                          onMouseOut={() => {
                            OpenCloseNav(false, "service")
                          }}
                          onClick={() => {
                            ClickNav("service", "/service")
                          }}
                        >
                          <div aria-label="service">
                            {node?.submenu?.length > 0 &&
                              node.submenu.map(node2 => (
                                <NavDropdown.Item href={node2?.CTALink}>
                                  {node2?.CTAText}
                                </NavDropdown.Item>
                              ))}
                          </div>
                        </NavDropdown>
                      ) : (
                        <>
                          {node.CTAText === "Products" ? (
                            <NavDropdown
                              title={node.CTAText}
                              id="Products"
                              className="border-right"
                              onMouseOver={() => {
                                OpenCloseNav(true, "Products")
                              }}
                              onMouseOut={() => {
                                OpenCloseNav(false, "Products")
                              }}
                              onClick={() => {
                                ClickNav("Products", "/product")
                              }}
                            >
                              <div aria-label="Products">
                                <ProductHeader
                                  setProductHeader={setProductHeader}
                                />
                              </div>
                            </NavDropdown>
                          ) : (
                            ""
                          )}

                          <div className="nav-item dropdown">
                            <Nav.Link
                              href={node.CTALink}
                              className={
                                node.CTAText === "Products"
                                  ? "dropdown-toggle border-right"
                                  : ""
                              }
                            >
                              {node.CTAText}
                            </Nav.Link>
                          </div>
                        </>
                      )}
                    </>
                  )
                })}
              </Nav>
              <div className="search-loup">
                <InputGroup.Append className="shadow-none has-search">
                  <FormControl
                    id={"SearchGllobal"}
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="Search"
                    className="shadow-none bg-light border-0"
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        navigate(
                          `/search-result?${
                            document.getElementById("SearchGllobal").value
                          }`
                        )
                      }
                    }}
                  />

                  <span
                    className=" form-control-feedback"
                    style={{ cursor: "pointer" }}
                    onClick={e => {
                      navigate(
                        `/search-result?${
                          document.getElementById("SearchGllobal").value
                        }`
                      )
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </InputGroup.Append>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      {showMenu && (
        <div className="container-fluid bg-light sub-mobile">
          <div className="container">
            <div className="col-lg-12 py-4 px-0">
              <div className="row">
                {showProduct && (
                  <div className="col-md-12">
                    <p>
                      {" "}
                      <Link to="#" className="w-100">
                        All
                      </Link>
                    </p>
                    {data.allMenuJson.edges.map(({ node, index }) => {
                      return (
                        <>
                          {node.CTAText !== "Products" ? (
                            <p>
                              <Link to={node.CTALink}> {node.CTAText}</Link>
                              {node?.submenu?.length > 0 && (
                                <FontAwesomeIcon
                                  onClick={() => {
                                    if (node?.submenu?.length > 0) {
                                      setShowProduct(x => !x)
                                      setSectionTwo(true)
                                      ;(node?.submenu ?? []).forEach(x => {
                                        x.Title = x.CTAText
                                      })
                                      setProductforMobile(node?.submenu)
                                      setProductName(node.CTAText)
                                    }
                                  }}
                                  className="font-size-sm float-right"
                                  icon={faChevronRight}
                                />
                              )}
                            </p>
                          ) : (
                            <p>
                              {" "}
                              <Link to={node.CTALink}>{node.CTAText}</Link>{" "}
                              <FontAwesomeIcon
                                className="font-size-sm float-right"
                                icon={faChevronRight}
                                onClick={() => {
                                  setProductforMobile(productMenu)
                                  setShowProduct(x => !x)
                                  setSectionTwo(true)
                                  setProductName(node.CTAText)
                                }}
                              />
                            </p>
                          )}
                        </>
                      )
                    })}
                  </div>
                )}
                {showSectionTwo && (
                  <div className="col-md-12">
                    <p>
                      <Link to="#" className="w-100">
                        All / {ProductName}
                      </Link>
                      <button
                        className="bg-white px-2 float-right mb-0"
                        onClick={() => {
                          setShowProduct(x => !x)
                          setSectionTwo(false)
                        }}
                        onKeyDown={() => {
                          setShowProduct(x => !x)
                          setSectionTwo(false)
                        }}
                      >
                        Back
                      </button>
                    </p>
                    {ProductforMobile?.map((node, index) => (
                      <p>
                        <Link
                          key={index}
                          to={
                            node?.CTALink ?? "/products/" + node?.node?.CTALink
                          }
                        >
                          {node?.Title ?? node?.node?.Title}
                        </Link>
                        {(
                          node?.RelatedProduct ??
                          node?.node?.RelatedProduct ??
                          []
                        ).length > 0 ? (
                          <FontAwesomeIcon
                            className="font-size-sm float-right"
                            icon={faChevronRight}
                            onClick={() => {
                              setSectionTwo(false)
                              setShowDetailProduct(x => !x)
                              setLevel3(
                                node?.RelatedProduct ??
                                  node?.node?.RelatedProduct ??
                                  []
                              )
                              setLevel3Name(node?.Title ?? node?.node?.Title)
                              setLevel3Link(
                                node?.CTALink ?? node?.node?.CTALink
                              )
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                    ))}
                  </div>
                )}
                {showDetailProduct ? (
                  <div className="col-md-12">
                    <p>
                      <Link to="#" className="w-100">
                        All / {ProductName} / {Level3Name}
                      </Link>
                      <button
                        className="bg-white px-2 float-right mb-0"
                        onClick={() => {
                          setShowDetailProduct(x => !x)
                          setSectionTwo(x => !x)
                        }}
                        onKeyDown={() => {
                          setShowProduct(x => !x)
                          setSectionTwo(false)
                        }}
                      >
                        Back
                      </button>
                    </p>
                    {Level3.map((x, i) => (
                      <p key={i}>
                        <Link
                          to={
                            "/products/" +
                            Level3Link +
                            "#" +
                            x?.Title.replace(" ", "")
                          }
                        >
                          {x?.Title}
                        </Link>
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      {showSearch && (
        <div className="container-fluid px-0 d-block d-sm-none Search-mobile">
          <div className="col-md-12 px-0">
            <InputGroup.Append className="shadow-none has-search ">
              <FormControl
                id={"SearchGllobal"}
                placeholder="Search "
                aria-label="Search"
                aria-describedby="Search"
                className="shadow-none bg-light border-0"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    navigate(`/search-result?${e.target.value}`)
                  }
                }}
              />
              <span
                className="form-control-feedback"
                onClick={e => {
                  navigate(
                    `/search-result?${
                      document.getElementById("SearchGllobal").value
                    }`
                  )
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </InputGroup.Append>
          </div>
        </div>
      )}
    </>
  )
}
