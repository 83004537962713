import React, { useState } from "react"
import { InputGroup, FormControl, Button } from "react-bootstrap"
import CookieConsent from "react-cookie-consent"

import { Link, graphql, useStaticQuery } from "gatsby"
import ContactInfo from "./Contact_info"
import ProductFooter from "./products_footer"
export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      allHomeJson {
        nodes {
          QuickLinks {
            CTALink
            CTAText
          }
        }
      }
    }
  `)
  const [formState, setFormState] = useState({
    email: "",
  })
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "newsletter", ...formState }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))

    e.preventDefault()
  }
  return (
    <>
      <div className="container-fluid bg-light border-bottom" style={{ display: "none" }}>
        <div className="container">
          <div className="col-lg-12">
            <div className="row py-5">
              <div className="col-lg-6">
                <h4 className="font-weight-bolder">Our newsletter</h4>
                <p className="mb-1">
                  Keep up to date with our latest news and industry
                </p>
                <p>insights by subscribing to our newsletter.</p>
              </div>
              <div className="col-lg-6 ml-auto align-self-center">
                <form
                  onSubmit={handleSubmit}
                  name="newsletter"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="newsletter" />
                  <InputGroup className="mb-3">
                    <FormControl
                      aria-label="Email"
                      placeholder="Email"
                      aria-describedby="Subscribe"
                      id="email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={formState.email}
                      required
                    />
                    <InputGroup.Prepend>
                      <Button
                        type="submit"
                        variant="outline-secondary"
                        id="Subscribe"
                      >
                        Subscribe
                      </Button>
                    </InputGroup.Prepend>
                  </InputGroup>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="col-lg-10 mx-auto">
            <div className="row pt-5 justify-content-center">
              {/* <div className="col-lg-6">
                <h6 className="font-weight-bolder mb-3">Product</h6>
                <ProductFooter />
                {/*  <p>
                  <Link to="/">Plasma Arc Melting (PAM)</Link>
                </p>
                <p>
                  <Link to="/">Electron Beam Melting (EB)</Link>
                </p>
                <p>
                  <Link to="/">Vacuum Induction Melting (VIM)</Link>
                </p>
                <p>
                  <Link to="/">Vacuum Art Remelt (VAR)</Link>
                </p> 
              </div> */}
              {/* <div className="col-lg-3 align-self-center">

                <p>
                  <Link to="/">Casting</Link>
                </p>
                <p>
                  <Link to="/">Metal Powder Production</Link>
                </p>
                <p>
                  <Link to="/">Services & Parts</Link>
                </p>
                <p>
                  <Link to="/">R&D and Toll Melting</Link>
                </p>
              </div> */}
              <div className="col-lg-3 col-md-4">
                <h6 className="font-weight-bolder mb-3">Quick Links</h6>
                {data.allHomeJson.nodes.map(node => {
                  return node.QuickLinks
                    ? node.QuickLinks.map(q => (
                      <>
                        <p className="mb-1">
                          <Link to={q.CTALink}>{q.CTAText}</Link>
                        </p>
                      </>
                    ))
                    : null
                })}
              </div>
              <div className="col-lg-3 col-md-4">
                <h6 className="font-weight-bolder mb-3">Contact Us</h6>
                <ContactInfo />
              </div>

            </div>

          </div>
          <div className="col-md-12 border-bottom mt-2 pt-5">
            <Link to="/">
              <img
                src="/img/LogoFooter.svg"
                alt="Footer Logo"
                className="img-border"

              />
            </Link>
          </div>
          <div className="col-md-12 text-center py-5 mt-5 footer-bottom">
            <div className="row">
              <div className="col-10 ">
                <h6>
                  © {new Date().getFullYear()} Retech Systems LLC. All Rights
                  Reserved.
                </h6>
              </div>
              <div className="col-12">
                <InputGroup className="mb-3">
                  <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    enableDeclineButton
                    cookieName="gatsby-gdpr-google-analytics"
                  >
                    This website uses cookies to improve your experience. We’ll
                    assume you’re okay with this, but you can opt-out if you wish.
                  </CookieConsent>
                </InputGroup>
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}
