import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CookieConsent from "react-cookie-consent";
import Footer from "./Footer";
import Headerbar from "./Headerbar";
import { Link } from "gatsby"
config.autoAddCss = false;

export default function Layout({ children }) {

  return (
    <div className="Layout">

      <Headerbar />
      <div className="content">
        {children}
        {/* <div className="Popup">
          <CookieConsent
            location="top"
            buttonText={<> <FontAwesomeIcon
              className=" fa-lg"
              icon={faTimes}
            /></>}
            declineButtonText="Decline"
            overlay={true}
            cookieName="gatsby-gdpr-google-analytics"
          >
            <Link
              to="https://www.secowarwick.com/en/made-4-america/?pr_source=retech&pr_medium=popupameric&pr_campaign=popupmade4america"
              target='_blank'
            >
              <img
                src='/img/POPUP_EN.jpg'
                className="w-100"
                height={600}
                alt='popup'
              />
            </Link>
          </CookieConsent>
        </div> */}
      </div>
      <footer>
        <Footer />
      </footer>

    </div>
  )
}