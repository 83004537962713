import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const ContactInfo = () => {
    const data = useStaticQuery(graphql`
    query  {
        allHomeJson(filter: {FacebookLink: {ne: null}})  {
   
            edges {
              node {
                FacebookLink
              LinkedinLink
              Email
              NumberPhone
              }
            }
          }
    }
  `)


    return (
        <>
            {data.allHomeJson.edges.map(({ node, index }) => {
                return (
                    <>
                        <p className="mb-1">
                            <a href={'tel:' + node.NumberPhone} >{node.NumberPhone}</a>
                        </p>
                        <p className="mb-1">
                            <a href={'mailto:' + node.Email}>Email</a>
                        </p>
                        <p className="mb-1">
                            <Link to={node.LinkedinLink} target='_blank'>Linkedin</Link>
                        </p>
                        <p className="mb-1">
                            <Link to={node.FacebookLink} target='_blank'>Facebook</Link>
                        </p>
                    </>
                )
            })}


        </>)
}

export default ContactInfo

// export page query
